<template>
  <footer class="manatt-ventures-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div>©2025 Manatt, Phelps &amp; Phillips, LLP.</div>
          <div>All rights reserved.</div>
        </div>
        <div class="col-md-3">
          <h5>About</h5>
          <ul>
            <li><a href="/manatt-ventures/home">Home</a></li>
            <li
              ><a href="/manatt-ventures/about/the-entrepreneur-program"
                >The Entrepreneur Program</a
              ></li
            >
            <li><a href="/manatt-ventures/about/our-team">Our Team</a></li>
            <li><a href="/manatt-ventures/services">Services</a></li>

            <li><a href="/manatt-ventures/about/key-industries">Key Industries</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h5>Resource Center</h5>
          <ul>
            <li
              ><a href="/manatt-ventures/resource-center/thought-leadership"
                >Thought Leadership
              </a></li
            >
            <li><a href="/manatt-ventures/resource-center/quick-links">Quick Links</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h5>Legal</h5>
          <ul>
            <li><a href="https://www.manatt.com/disclaimer">Disclaimer</a></li>
            <li><a href="https://www.manatt.com/Privacy-Statement">Privacy Statement</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
