<template>
  <div class="layout-container">
    <ManattVenturesHeader />
    <main id="main-content" class="main-content">
      <slot />
    </main>
    <ManattVenturesFooter />
  </div>
</template>
<style scoped>
.layout-container {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
}

.main-content::before {
  height: 0px;
}
</style>
